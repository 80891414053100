import EmailButton from '@/components/emailButton';
import QRScanBox from '@/components/qrScanBox';
import TableWrapper from '@/components/tableWrapper';
import { getInvoiceBalance } from '@/pages/dashboard/commerce/multiPayments/utils';
import { Box, Stack, TableCell, Typography } from '@mui/material';
import { addDays, endOfMonth, startOfMonth } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { sum, toLower } from 'lodash-es';
import { Fragment } from 'react';
import currencyFormat from '../../../../helpers/currencyFormat';
import useGetDeviceInfo from '../../../../hooks/useGetDeviceInfo';
import type { Order } from '../../../../types/schema';

export default function InvoicesStatementView( {
	invoices,
	month,
	year,
	type,
	order,
	customDates,
	statementsTotal,
	excludePaidFromStatement,
	timezone = 'America/Chicago',
}: {
	invoices?: Order[],
	month?: string,
	year?: string,
	type?: 'month' | 'year' | 'none',
	statementsTotal?: number,
	order?: {
		client: { id: string, email: string },
		company: { id: string, metadata: { excludePaidFromStatement: boolean } }
	},
	customDates?: Date[],
	excludePaidFromStatement?: boolean,
	timezone?: string
} ) {
	const { isCloverDevice } = useGetDeviceInfo();
	
	const invoice = order || invoices?.[ 0 ];
	const excludePaid = invoice.company?.metadata?.excludePaidFromStatement;
	const paidTotals = ( invoices ?? [] ).reduce( ( sum, invoice ) => {
		const payments = invoice.payments;
		if ( payments.length ) {
			for ( const payment of payments ) {
				if ( ![ 'invoiss', 'haps' ].includes( toLower( payment.type ) ) && payment.status === 'PAID' ) {
					sum = [ ...sum, payment.amount + ( payment.tip || 0 ) || 0 ];
				}
			}
		}
		return sum;
	}, [] );
	
	const balancePaid = sum( paidTotals.filter( Boolean ) );
	
	const totalBalances = ( invoices ?? [] ).reduce( ( sum, invoice ) => {
		const balance = getInvoiceBalance( invoice );
		sum = [ ...sum, balance || 0 ];
		return sum;
	}, [] );
	
	const balance = sum( totalBalances.filter( Boolean ) );
	const totalOfGrand = statementsTotal || ( invoices ?? [] ).reduce( ( sum, order ) => sum + order.grandTotal, 0 );
	
	const state = {
		client                  : invoice.client?.id,
		excludePaidFromStatement: excludePaid,
		currentTab              : customDates ? 1 : 0,
		// clientEmail: order?.client?.email || null,
		customDates,
		type,
		month: type === 'month' && month ? month : undefined,
		year : type !== 'month' && type !== 'none' && !customDates ? year : undefined,
	};
	
	const pdfFilters = encodeURIComponent( Buffer.from( JSON.stringify( state ) )
		.toString( 'base64' ) );
	
	return (
		<Fragment>
			<Stack direction='row'>
				<Box sx={{ width: '70%' }}>
					{!excludePaid ? (
						<Typography sx={{ pt: 1, mt: 3, color: 'text.secondary' }}>
							Total: {currencyFormat( totalOfGrand || 0 )}
						</Typography>
					) : null}
					{balancePaid > 0 && !excludePaid && (
						<Typography sx={{ pt: 1, mb: 3, color: 'text.secondary' }}>
							Paid: {currencyFormat( balancePaid )}
						</Typography>
					)}
					{balancePaid !== totalOfGrand && balance > 0 && (
						<Typography variant='h2' sx={{ fontSize: { xs: 18, sm: 25 }, pt: 1, my: 3 }}>
							Balance: {currencyFormat( balance )}
						</Typography>
					)}
				</Box>
				<Box sx={{ width: '30%', textAlign: 'right' }}>
					{invoice.client?.id && (
						<QRScanBox
							data={`${process.env.NEXT_PUBLIC_SITE_URL}/client/statement?s=${pdfFilters}`}
						/>
					)}
				</Box>
			</Stack>
			<TableWrapper>
				<TableCell sx={{ my: 2 }}>
					{type === 'none' ? 'For all past months.'
						: customDates
							? `From ${formatInTimeZone( new Date( customDates[ 0 ] ), timezone, 'MMMM dd yyyy' )} - ${formatInTimeZone( new Date( customDates[ 1 ] ), timezone, 'MMMM dd yyyy' )}`
							: type === 'month' && month
								? `${formatInTimeZone( startOfMonth( new Date( month ) ), timezone, 'MMMM dd yyyy',
								)} - ${formatInTimeZone( endOfMonth( new Date( month ) ), timezone, 'MMMM dd yyyy',
								)}`
								: `For the Year of ${formatInTimeZone( addDays( year
									? new Date( year )
									: new Date(), 1 ), timezone, 'yyyy' )}`}
				</TableCell>
			</TableWrapper>
			{!isCloverDevice && (
				<Box>
					<EmailButton
						target='_blank'
						href={`${process.env.NEXT_PUBLIC_SITE_URL}/client/statement?s=${pdfFilters}`}>
						View Details
					</EmailButton>
				</Box>
			)}
			{!isCloverDevice && (
				<Box sx={{ mt: 2 }}>
					<EmailButton
						variant='text'
						target='_blank'
						href={`${process.env.NEXT_PUBLIC_SITE_URL}/api/preview/pdf/${invoice.company.id}/clientStatements?s=${pdfFilters}${timezone
							? `&timezone=${timezone}`
							: ''}`}>
						Download PDF
					</EmailButton>
				</Box>
			)}
		</Fragment>
	);
}
