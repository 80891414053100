import EmailButton from '@/components/emailButton';
import currencyFormat from '@/helpers/currencyFormat';
import { Statuses } from '@/pages/dashboard/commerce/components/statuses';
import { Order, Payment } from '@/types/schema';
import { Chip, Stack, Typography } from '@mui/material';
import { toLower } from 'lodash-es';
import { Fragment } from 'react';

export default function PaymentReceiptEmailView( { payment, invoice }: { payment: Payment, invoice?: Order } ) {
	
	return (
		<Fragment>
			<Typography variant='h3' sx={{ mb: 2, textAlign: 'center' }}>
				Your transaction was {toLower( payment.status )}
			</Typography>
			<Stack sx={{ justifyContent: 'space-between', my: 3 }}>
				<Typography variant='h3'>
					{currencyFormat( payment?.amount )}
				</Typography>
				<Chip
					variant='alpha'
					sx={{ m: 'auto' }}
					color={Statuses[ payment.status ]?.color}
					label={Statuses[ payment.status ]?.label}
				/>
			</Stack>
			{payment?.note && (
				<Typography sx={{ my: 3 }}>
					{payment.note}
				</Typography>
			)}
			<Typography sx={{ color: 'text.secondary' }}>
				Please view the detail of your transaction below
			</Typography>
			{( invoice?.id || payment.order?.id ) && (
				<EmailButton
					target='_blank'
					href={`${process.env.NEXT_PUBLIC_SITE_URL}/p/${invoice?.id || payment.order?.id}/${toLower( invoice?.type || payment.order?.type )}`}>
					View Receipt
				</EmailButton>
			)}
		</Fragment>
	
	);
}
