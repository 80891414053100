import EmailButton from '@/components/emailButton';
import ReminderCommercesAndTotal from '@/components/reminderCommercesAndTotal';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { toLower, upperFirst } from 'lodash-es';
import React, { Fragment } from 'react';
import type { Order } from '../../../../types/schema';

export default function ReminderCommercesEmailView( {
	invoices,
	timezone,
	emailView,
}: {
	invoices: Order[],
	timezone?: string,
	emailView?: boolean
} ) {
	
	const invoice = invoices?.[ 0 ];
	
	return (
		<Fragment>
			<ReminderCommercesAndTotal invoices={invoices} timezone={timezone}/>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell sx={{ border: '0 !important', padding: '5px !important' }}/>
					</TableRow>
				</TableBody>
			</Table>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell sx={{ border: '0 !important', padding: '5px !important' }}/>
					</TableRow>
				</TableBody>
			</Table>
			<EmailButton
				emailView={emailView}
				target='_blank'
				href={`${process.env.NEXT_PUBLIC_SERVER_URL}/login?redirect=${process.env.NEXT_PUBLIC_SERVER_URL}/client`}>
				View all {upperFirst( toLower( invoice?.type ) )}s
			</EmailButton>
			<Typography color='text.secondary' py={2}>
				Access and manage your {toLower( invoice?.type )}s, saved cards, and download statements.
			</Typography>
		</Fragment>
	);
}
