import EmailButton from '@/components/emailButton';
import TableWrapper from '@/components/tableWrapper';
import type { HouseAccount, Order } from '@/types/schema';
import { Box, Stack, TableCell, Typography } from '@mui/material';
import { endOfMonth, format, startOfMonth, subDays } from 'date-fns';
import { Fragment } from 'react';
import currencyFormat from '../../../../helpers/currencyFormat';
import useGetDeviceInfo from '../../../../hooks/useGetDeviceInfo';

export default function HouseAccountInvoicesStatement( {
	invoices,
	houseAccount,
	month,
	balance,
	houseAccountId,
	newInvoiceId,
}: {
	invoices: Order[],
	month: string,
	balance: number | undefined,
	houseAccount?: HouseAccount,
	houseAccountId: string,
	newInvoiceId?: string
} ) {
	const { isCloverDevice } = useGetDeviceInfo();
	
	const invoice = invoices[ 0 ];
	const balanceDue = balance || 0;
	
	const state = {
		houseAccountId: houseAccountId || invoice?.houseAccount?.id || houseAccount?.id,
		month         : month,
	};
	
	const pdfFilters = encodeURIComponent( Buffer.from( JSON.stringify( state ) )
		.toString( 'base64' ) );
	
	const timePeriod = month === 'AllMonths'
		? `Until ${format( subDays( new Date(), 1 ), 'MMMM yyyy' )}`
		: `${format( startOfMonth( new Date( month ) ), 'MMMM dd yyyy' )} - ${format( endOfMonth( new Date( month ) ), 'MMMM dd yyyy' )}`;
	
	return (
		<Fragment>
			<Stack direction='row'>
				<Box sx={{ width: '70%' }}>
					<Typography sx={{ fontSize: { xs: 18, sm: 25 }, pt: 1, my: 3, color: 'text.secondary' }}>
						Balance: {currencyFormat( balanceDue )}
					</Typography>
				</Box>
				{/*<Box sx={{ width: '30%', textAlign: 'right' }}>*/}
				{/*	{invoice.houseAccount?.id && (*/}
				{/*		<QRScanBox*/}
				{/*			data={`${process.env.NEXT_PUBLIC_SITE_URL}/client/statement?$month=${month}%26id=${invoice.houseAccount.id}`}*/}
				{/*		/>*/}
				{/*	)}*/}
				{/*</Box>*/}
			</Stack>
			<TableWrapper>
				<TableCell sx={{ my: 2 }}>
					{timePeriod}
				</TableCell>
			</TableWrapper>
			<TableWrapper>
				<TableCell sx={{ my: 1 }}>
					To pay online please sign up and sign in
					using {invoice?.houseAccount?.email || invoice?.houseAccount?.client?.email || invoice?.client?.email || 'your email on file'}
				</TableCell>
			</TableWrapper>
			{!isCloverDevice && !newInvoiceId && (
				<Box>
					<EmailButton
						target='_blank'
						href={`${process.env.NEXT_PUBLIC_SERVER_URL}/login?redirect=${process.env.NEXT_PUBLIC_SITE_URL}/client/invoices`}>
						View Details
					</EmailButton>
				</Box>
			)}
			{!isCloverDevice && newInvoiceId && (
				<Box>
					<EmailButton
						target='_blank'
						href={`${process.env.NEXT_PUBLIC_SITE_URL}/p/${newInvoiceId}/invoice`}>
						View Details
					</EmailButton>
				</Box>
			)}
			{!isCloverDevice && (
				<Box sx={{ mt: 2 }}>
					<EmailButton
						variant='text'
						target='_blank'
						href={`${process.env.NEXT_PUBLIC_SITE_URL}/api/preview/pdf/${invoice?.company?.id || houseAccount?.company?.id}/houseAccountInvoices?s=${pdfFilters}`}>
						Download PDF
					</EmailButton>
				</Box>
			)}
		</Fragment>
	);
}
