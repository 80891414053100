import { Company } from '@/types/schema';
import { Stack, Typography } from '@mui/material';

export default function AccountEmailView( { note }: { note: string, company: Company } ) {
	
	return (
		<Stack spacing={2} sx={{ display: 'block' }}>
			{note && (
				<Typography
					style={{ fontSize: '14px !important' }}
					sx={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
					{note}
				</Typography>
			)}
		</Stack>
	);
}
